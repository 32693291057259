import React from 'react';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import TitleComponent from './TitleComponent';
import { labelInputs } from './constants/contract-chip-inputs';

const ContractInfoComponent = ({ contractInfo, setContractInfo }) => {
  // eslint-disable-next-line no-unused-vars
  const handleContractInfo = (eventId, eventValue, id) => {
    const newData = { ...contractInfo };
    if (eventId === 'address') {
      newData[eventId] = eventValue;
    } else {
      newData[eventId] = eventValue.toLowerCase();
    }
    setContractInfo(newData);
  };

  return (
    <>
      <TitleComponent number="2" label="Complete los datos para el Contrato:" />
      <Stack alignItems="center" spacing={2}>
        {labelInputs.map((rowsInput) => (
          <Stack
            direction="row"
            spacing={2}
            sx={{ width: '100%' }}
            key={`contract-info-row-${rowsInput[0].index}`}
          >
            {rowsInput.map((input) => (
              <input.component
                key={`contract-info-input-${input.id}`}
                input={input}
                obj={contractInfo}
                handleInfoFunc={handleContractInfo}
              />
            ))}
          </Stack>
        ))}
      </Stack>
    </>
  );
};

ContractInfoComponent.propTypes = {
  contractInfo: PropTypes.shape({
    mainActivity: PropTypes.string,
    rut: PropTypes.string,
    name: PropTypes.string,
    address: PropTypes.string,
    comuna: PropTypes.string,
    mainDisabled: PropTypes.bool,
  }),
  setContractInfo: PropTypes.func.isRequired,
};

ContractInfoComponent.defaultProps = {
  contractInfo: {
    mainActivity: '',
    rut: '',
    name: '',
    address: '',
    comuna: '',
    mainDisabled: true,
  },
};

export default ContractInfoComponent;
