import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import PropTypes from 'prop-types';
import TitleComponent from './TitleComponent';
import { endorsementsInputs, endorsementsSpouseInputs } from './constants/contract-chip-inputs';

const EndorsementComponent = ({ endorsements, setEndorsements }) => {
  const handleAddEndorsement = () => {
    const newItem = [{
      id: endorsements.length,
      label: 'SR',
      name: '',
      rut: '',
      personeriaComments: '',
      nationality: 'chileno',
      profession: '',
      civilStatus: 'single',
      address: '',
      email: '',
      mainDisabled: false,
      spouceName: '',
      spouceLabel: 'SRA',
      spouceRut: '',
      spouceAddress: '',
      spouceNationality: '',
    }];
    setEndorsements(endorsements.concat(newItem));
  };

  const handleDeleteEndorsement = (endorsementId) => {
    setEndorsements(endorsements.filter((item) => item.id !== endorsementId));
  };
  const handleEndorsementInfo = (eventId, eventValue, endorsementId) => {
    const newItem = endorsements.find((item) => item.id === endorsementId);
    newItem[eventId] = eventValue;
    setEndorsements(endorsements.map((u) => (u.id !== newItem.id ? u : newItem)));
  };

  return (
    <>
      <TitleComponent number="2.2" label="Avales/Fiadores:">
        <AddCircleIcon
          color="primary"
          onClick={handleAddEndorsement}
          sx={{ cursor: 'pointer' }}
        />
      </TitleComponent>
      <Stack alignItems="center" spacing={2}>
        {endorsements.map((end, index) => (
          <Stack direction="column" key={end.id}>
            <TitleComponent number={index + 1}>
              <Divider width="75%" />
              <Tooltip title={`Eliminar Aval ${end.name}`}>
                <RemoveCircleIcon
                  color="primary"
                  onClick={() => handleDeleteEndorsement(end.id)}
                  sx={{ cursor: 'pointer' }}
                />
              </Tooltip>
            </TitleComponent>
            <Stack alignItems="center" spacing={2} sx={{ marginTop: 2 }}>
              {endorsementsInputs.map((rowsInput) => (
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{ width: '100%' }}
                  key={`endorsement-row-${rowsInput[0].index}`}
                >
                  {rowsInput.map((input) => (
                    <input.component
                      key={`endorsement-input-${input.id}-${end.id}`}
                      input={input}
                      obj={end}
                      handleInfoFunc={handleEndorsementInfo}
                    />
                  ))}
                </Stack>
              ))}
              {end.civilStatus === 'married_society' && endorsementsSpouseInputs.map((rowsInput) => (
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{ width: '100%' }}
                  key={`endorsement-spouce-row-${rowsInput[0].index}`}
                >
                  {rowsInput.map((input) => (
                    <input.component
                      key={`endorsement-spouce-input-${input.id}-${end.id}`}
                      input={input}
                      obj={end}
                      handleInfoFunc={handleEndorsementInfo}
                    />
                  ))}
                </Stack>
              ))}
            </Stack>
          </Stack>
        ))}
      </Stack>
    </>
  );
};

EndorsementComponent.propTypes = {
  endorsements: PropTypes.arrayOf(PropTypes.shape).isRequired,
  setEndorsements: PropTypes.func.isRequired,
};

export default EndorsementComponent;
