import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import { LoadingButton } from '@mui/lab';

const ContinueButtonComponent = (
  { contractInfo,
    legalRepresentatives,
    endorsements,
    genereateCompanyFrameworkContract,
    generateLoading,
  },
) => {
  const disableIfAnyEmptyProp = (obj, keys) => {
    for (let i = 0; i < keys.length; i += 1) {
      if (obj[keys[i]] === '' || obj[keys[i]] === null) {
        return true;
      }
    }
    return false;
  };

  const handleDisableButton = useCallback(() => {
    if (disableIfAnyEmptyProp(contractInfo, Object.keys(contractInfo))) return true;
    for (let i = 0; i < legalRepresentatives.length; i += 1) {
      if (disableIfAnyEmptyProp(legalRepresentatives[i], Object.keys(legalRepresentatives[i]))
      ) return true;
    }
    for (let i = 0; i < endorsements.length; i += 1) {
      const disableSpouceEmptyProps = endorsements[i].civilStatus !== 'married_society';
      const endorsementProps = ['name', 'rut', 'nationality', 'civilStatus', 'profession', 'address', 'email', 'label', 'personeriaComments'];
      const searchProps = disableSpouceEmptyProps ? endorsementProps : Object.keys(endorsements[i]).filter((item) => item !== 'spouceMasterEntityId');
      if (disableIfAnyEmptyProp(endorsements[i], searchProps)) return true;
    }
    return false;
  }, [contractInfo, legalRepresentatives, endorsements]);

  const TooltipHandler = useMemo(() => {
    if (handleDisableButton()) return 'Se necesita todos los campos con datos';
    if (!legalRepresentatives.length) return 'Se debe tener al menos un rep. legal';
    return '';
  }, [legalRepresentatives, handleDisableButton()]);

  return (
    <Tooltip title={TooltipHandler}>
      <span>
        <LoadingButton
          size="small"
          variant="contained"
          loading={generateLoading}
          onClick={genereateCompanyFrameworkContract}
          disabled={handleDisableButton() || !legalRepresentatives.length}
        >
          Continuar
        </LoadingButton>
      </span>
    </Tooltip>
  );
};

ContinueButtonComponent.propTypes = {
  genereateCompanyFrameworkContract: PropTypes.func.isRequired,
  generateLoading: PropTypes.bool.isRequired,
  contractInfo: PropTypes.shape().isRequired,
  legalRepresentatives: PropTypes.arrayOf(
    PropTypes.shape(),
  ).isRequired,
  endorsements: PropTypes.arrayOf(
    PropTypes.shape(),
  ).isRequired,
};

export default ContinueButtonComponent;
